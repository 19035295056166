import axios from "axios";

import envs from "../../env";
import globalStore from "../store/index";
import { getHeaders } from "../helpers/store_utils";
import dateFormater from "../helpers/date_formater";

var store = globalStore.state.country;

export const list = async () => {
  const { data } = await axios.get(`${envs.API_URL}country/list`, {
    headers: getHeaders({ rootState: globalStore.state }),
  });
  console.log(data.body)
  store.list = data.body
};

export const get = async (countryID) => {
  const { data } = await axios.get(`${envs.API_URL}country/get/${countryID}`, {
    headers: getHeaders({ rootState: globalStore.state }),
  });

  store.view = {
    id: data.body.id,
    name: JSON.parse(data.body.name),
  };

  return data;
};

export const create = async () => {

  await axios.post(`${envs.API_URL}country/create`, store.view, {
    headers: getHeaders({ rootState: globalStore.state }),
  });
  return true;
};

export const edit = async () => {
  await axios.post(`${envs.API_URL}country/edit`, store.view, {
    headers: getHeaders({ rootState: globalStore.state }),
  });

  return true;
};

export const remove = async () => {
  await axios.get(`${envs.API_URL}country/remove/${store.view.id}`, {
    headers: getHeaders({ rootState }),
  });

  return true;
};
