<template>
  <f-card>
    <v-row>
      <v-col cols="12">
        <f-card-title>Extras</f-card-title>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-switch
          :disabled="!permissions.write"
          hide-details
          v-model="distributor.champion"
          label="Champion"
        />
        <br />
      </v-col>
    </v-row>
    <v-row v-if="distributor.region == 'laam'">
      <v-col cols="12">
        <v-switch
          :disabled="!permissions.write"
          hide-details
          v-model="distributor.master"
          label="Master"
        />
      </v-col>
    </v-row>
  </f-card>
</template>

<script>
export default {
  data: () => ({
    permissions: {
      read: false,
      write: false,
    },
  }),
  created() {
    this.permissions = this.$getPermissions();
  },
  computed: {
    distributor() {
      return this.$store.state.distributor.view;
    },
  },
};
</script>
