import { getErrorMessages } from './error_utils'

export const getDistributorErrorMessages = (errors) => {
  const values = {
    duplicatedCnpj: 'O CNPJ informado já está sendo usado por outro Distribuidor',
    duplicatedRelationCnpj: 'Um ou mais dos CNPJs informados já está(ão) sendo usado(s) por outro Distribuidor',
    default: 'Erro ao criar Distribuidor',
  }
  return getErrorMessages(values, errors)
}
