export const getErrorMessages = (values, errors) => {
  const output = []
  if (Array.isArray(errors) && errors.length > 0) {
    errors.forEach((item) => {
      if (values[item]) {
        output.push(values[item])
      }
    })
  } 
  if (output.length === 0) {
    output.push(values.default)
  }
  return output.join(', ')
}
