<template>
	<f-card :class="{ 'global-enabled': isBlocking }">
		<f-expandable-div :expanded="view.current_state == view.states.LISTING">
			<v-row>
				<v-col cols="12">
					<f-card-title>
						{{ $t("labels.countries") }}
						<f-card-title-button @click.native="add" v-if="permissions.write" />
					</f-card-title>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div
						v-for="(item, index) in countriesFiltered"
						:key="index"
						class="f-inner-list">
						{{ item.name }}

						<div v-if="permissions.write">
							<span @click="remove(index)" class="f-inner-list--icon">
								<v-icon>mdi-close</v-icon>
							</span>
						</div>
					</div>
				</v-col>
			</v-row>
		</f-expandable-div>

		<f-expandable-div :expanded="view.current_state != view.states.LISTING">
			<v-row>
				<v-col cols="12">
					<f-card-title>{{ $t("labels.countries") }}</f-card-title>
				</v-col>

				<v-col cols="6">
					<v-select
						v-model="country.id"
						outlined
						dense
						hide-details="auto"
						:items="list"
						item-text="name"
						item-value="id"
						:label="$t('labels.selectCountry')" />
				</v-col>
				<v-col cols="6" class="f-btn-col">
					<v-btn elevation="0" color="grey" @click="save">
						{{ $t("labels.btnAdd") }}
					</v-btn>
					<v-btn elevation="0" @click="clear_view">
						{{ $t("labels.btnCancel") }}
					</v-btn>
				</v-col>
			</v-row>
		</f-expandable-div>
	</f-card>
</template>

<script>
import { get, list } from "../../services/country";
import { getCountries } from "../../services/distributors";

export default {
	data: () => ({
		country: {},
		view: {
			current_state: "LISTING",
			states: {
				ADDING: "ADDING",
				EDITING: "EDITING",
				LISTING: "LISTING",
			},
		},
		refresh: 0,
		permissions: {
			read: false,
			write: false,
		},
		isBlocking: false,
	}),
	computed: {
		countries() {
			return this.$store.state.country.selected;
		},
		countriesFiltered() {
			try {
				return this.countries.filter((item) => item.action != "remove");
			} catch (_) {
				return [];
			}
		},
		list() {
			return this.$store.state.country.list.map((item) => {
				try {
					const names = JSON.parse(item.name);
					item.name = names[this.currentLanguage];
					return item;
				} catch (_) {
					return "";
				}
			});
		},
		currentLanguage() {
			return this.$i18n.locale.toLocaleLowerCase();
		},
	},
	watch: {
		"view.current_state"(val) {
			if (val == this.view.states.LISTING) {
				this.isBlocking = false;
				this.$store.state.system.disabling = false;
			} else {
				this.isBlocking = true;
				this.$store.state.system.disabling = true;
			}
		},
	},
	created() {
		this.permissions = this.$getPermissions();
		list();
		getCountries(this.$route.params.id);
	},
	methods: {
		async getData() {},
		add() {
			this.view.current_state = this.view.states.ADDING;
		},
		save() {
			const country = {
				id: this.country.id,
				action: "new",
				name: this.list.find((item) => item.id == this.country.id).name,
			};

			this.countries.push(country);
			this.clear_view();
		},
		clear_view() {
			this.country.name = "";
			this.view.current_state = this.view.states.LISTING;
		},
		async remove(index) {
			var response = await this.$store.dispatch("dialog/show", {
				title: "Remover",
				content: "Você deseja remover esse País?",
			});

			if (response) {
				if (this.countries[index].action == "new") {
					this.countries.splice(index, 1);
				} else {
					this.countries[index].action = "remove";
				}
			}
		},
	},
};
</script>
