<!-- @TODO: continue over here -->
<template>
	<div>
		<v-row>
			<v-col cols="12 pa-8">
				<v-btn-toggle
					v-model="distributor.region"
					mandatory
					active-class="active-region">
					<v-btn value="laam">LATAM</v-btn>
					<v-btn value="br">BR</v-btn>
				</v-btn-toggle>
			</v-col>
		</v-row>

		<f-card>
			<v-row>
				<v-col cols="12">
					<f-card-title>{{ $t("labels.data") }}</f-card-title>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="4">
					<v-text-field
						:disabled="!permissions.write"
						outlined
						dense
						:label="$t('labels.distributorName')"
						v-model="distributor.name" />

					<!-- v-if inputs : begin -->
					<v-text-field
						:disabled="!permissions.write"
						outlined
						dense
						label="CNPJ"
						v-mask="'##.###.###/####-##'"
						v-model="distributor.cnpj"
						v-if="!isLaam" />
					<v-text-field
						:disabled="!permissions.write"
						outlined
						dense
						:label="$t('labels.distributorCompanyId')"
						v-model="distributor.cnpj"
						v-if="isLaam" />
					<!-- v-if inputs : end -->
				</v-col>
			</v-row>
		</f-card>
	</div>
</template>

<script>
export default {
	data: () => ({
		permissions: {
			read: false,
			write: false,
		},
		region: "laam",
	}),
	created() {
		this.permissions = this.$getPermissions();
	},
	computed: {
		distributor() {
			return this.$store.state.distributor.view;
		},
		isLaam() {
			return this.distributor.region == "laam";
		},
	},
};
</script>

<style lang="scss">
.active-region {
	background-color: #ffc107 !important;
	border: 1px solid#ffc107 !important;
	color: black !important;

	&::before {
		background-color: transparent !important;
	}
}
</style>
