import axios from "axios";

import { i18n } from "../main";
import envs from "../../env";
import globalStore from "../store/index";
import { getHeaders } from "../helpers/store_utils";

var store = globalStore.state.distributor;

export const getCountries = async (distributorID) => {
  const { data } = await axios.get(
    `${envs.API_URL}distributor/get_countries/${distributorID}`,
    {
      headers: getHeaders({ rootState: globalStore.state }),
    }
  );

  var countryStore = globalStore.state.country;
  countryStore.selected = [];

  try {
    data.body.forEach((element) => {
      const names = JSON.parse(element.name);
      countryStore.selected.push({
        id: element.id,
        name: names[i18n.locale.toLocaleLowerCase()],
        action: "keep",
      });
    });
  } catch (_) {}
};

export const get = async (distributorID) => {
  var distributor = {};

  const distributorResult = await axios.get(
    `${envs.API_URL}distributor/get/${distributorID}`,
    {
      headers: getHeaders({ rootState: globalStore.state }),
    }
  );
  distributor = distributorResult.data.body;
  store.view = distributor;

  return true;
};
