import DistributorCreateEditForm from "../../components/distributors/CreateEditForm.vue";
import ContractsForm from "../../components/distributors/Contracts.vue";
import CnpjsForm from "../../components/distributors/Cnpjs.vue";
import CountriesForm from "../../components/distributors/Countries.vue";
import OtherForm from "../../components/distributors/Other.vue";
import { getDistributorErrorMessages } from "../../helpers/distributor_utils";

export default {
  components: {
    DistributorCreateEditForm,
    ContractsForm,
    CnpjsForm,
    CountriesForm,
    OtherForm,
  },
  data: () => ({
    flags: {
      creating: false,
    },
    distributorID: null,
    permissions: {
      read: false,
      write: false,
    },
  }),
  created() {
    console.log(this.$route.name)
    this.flags.creating = this.$route.name == "distributor-create";
    if (!this.flags.creating) {
      this.distributorID = this.$route.params.id;
      this.getData();
    }
    this.permissions = this.$getPermissions();
  },
  computed: {
    distributor() {
      return this.$store.state.distributor.view;
    },
  },
  watch: {
    "$route.name": function (value) {
      if (value == "distributor-create") {
        this.flags.creating = true;
        this.$store.dispatch("distributor/clear", "view");
      }
    },
  },
  methods: {
    async save() {
      if (this.flags.creating) {
        if (!this.$store.state.distributor.view.cnpjs.length) {
          this.$store.commit("snackbar/show", {
            content: "É necessário ao menos 1 CNPJ!",
            type: "error",
          });
          return;
        }
        const result = await this.$store.dispatch("distributor/create");
        if (result.status) {
          this.$store.commit("snackbar/show", {
            content: "Distribuidor criado com sucesso!",
            type: "success",
          });
          this.$router.push({ name: "distributor-list" });
        } else {
          const message = getDistributorErrorMessages(result.body.errors || []);
          this.$store.commit("snackbar/show", {
            content: message,
            type: "error",
          });
        }
      } else {
        const result = await this.$store.dispatch("distributor/edit");
        if (result.status) {
          this.$store.commit("snackbar/show", {
            content: "Distribuidor editado com sucesso!",
            type: "success",
          });
          this.$router.push({ name: "distributor-list" });
        } else {
          const message = getDistributorErrorMessages(result.body.errors || []);
          this.$store.commit("snackbar/show", {
            content: message,
            type: "error",
          });
        }
      }
    },
    async getData() {
      await this.$store.dispatch("distributor/get", this.distributorID);
    },
    async remove() {
      var response = await this.$store.dispatch("dialog/show", {
        title: "Remover",
        content: "Você deseja remover esse distributore?",
      });
      if (response) {
        await this.$store.dispatch("distributor/remove");
        this.$router.push({
          name: "distributor-list",
        });
      }
    },
    cancel() {
      this.$router.push({
        name: "distributor-list",
      });
    },
  },
  destroyed() {
    this.$store.dispatch("distributor/clear", "view");
  },
};
