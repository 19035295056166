export default {
  data: () => ({
    contract: {},
    view: {
      current_state: "LISTING",
      states: {
        ADDING: "ADDING",
        EDITING: "EDITING",
        LISTING: "LISTING",
      },
    },
    permissions: {
      read: false,
      write: false,
    },
  }),
  computed: {
    contracts() {
      return this.$store.state.distributor.view.contracts;
    },
  },
  created() {
    this.permissions = this.$getPermissions();
    if (!this.$store.state.distributor.view.contracts) {
      this.$store.state.distributor.view.contracts = [];
    }
  },
  methods: {
    add() {
      this.view.current_state = this.view.states.ADDING;
    },
    save() {
      if (this.view.current_state == this.view.states.ADDING) {
        this.contracts.push({
          code: this.contract.code,
          action: "add",
        });
      } else {
        var contract = this.contracts[this.contract.index];
        contract.code = this.contract.code;
        contract.action = "edit";
        this.contracts[this.contract.index] = contract;
      }
      this.clear_view();
    },
    edit(index) {
      this.contract.index = index;
      this.contract.code = this.contracts[index].code;
      this.view.current_state = this.view.states.EDITING;
    },
    clear_view() {
      this.contract.code = "";
      this.view.current_state = this.view.states.LISTING;
    },
    async remove(index) {
      var response = await this.$store.dispatch("dialog/show", {
        title: this.$t('labels.remove'),
        content: this.$t('alerts.removeContract'),
      });
      if (
        this.$route.name == "distributor-edit" &&
        typeof this.contracts[index].id != "undefined"
      ) {
        await this.$store.dispatch(
          "distributor/removeContract",
          this.contracts[index].id
        );
        this.$store.commit("snackbar/show", {
          content: this.$t('alerts.successRemoveContract'),
          type: "success",
        });
      }
      if (response) {
        this.contracts.splice(index, 1);
      }
    },
  },
};
