export default {
	data: () => ({
		cnpj: {},
		view: {
			current_state: "LISTING",
			states: {
				ADDING: "ADDING",
				EDITING: "EDITING",
				LISTING: "LISTING",
			},
		},
		permissions: {
			read: false,
			write: false,
		},
		isBlocking: false,
	}),
	computed: {
		cnpjs() {
			return this.$store.state.distributor.view.cnpjs;
		},
		isLaam() {
			return this.$store.state.distributor.view.region == "laam";
		},
	},
	watch: {
		"view.current_state"(val) {
			if (val == this.view.states.LISTING) {
				this.isBlocking = false;
				this.$store.state.system.disabling = false;
			} else {
				this.isBlocking = true;
				this.$store.state.system.disabling = true;
			}
		},
	},
	created() {
		this.permissions = this.$getPermissions();
		if (!this.$store.state.distributor.view.cnpjs) {
			this.$store.state.distributor.view.cnpjs = [];
		}
	},
	methods: {
		add() {
			this.view.current_state = this.view.states.ADDING;
		},
		save() {
			if (this.view.current_state == this.view.states.ADDING) {
				this.cnpjs.push({
					cnpj: this.cnpj.number,
					title: this.cnpj.title,
					action: "add",
				});
			} else {
				var cnpj = this.cnpjs[this.cnpj.index];
				cnpj.cnpj = this.cnpj.number;
				cnpj.title = this.cnpj.title;
				cnpj.action = "edit";
				this.cnpjs[this.cnpj.index] = cnpj;
			}
			this.clear_view();
		},
		edit(index) {
			// console.log(this.cnpjs[index])
			this.cnpj.index = index;
			this.cnpj.number = this.cnpjs[index].cnpj;
			this.cnpj.title = this.cnpjs[index].title;
			this.view.current_state = this.view.states.EDITING;
		},
		clear_view() {
			this.cnpj.code = "";
			this.cnpj.title = "";
			this.view.current_state = this.view.states.LISTING;
		},
		async remove(index) {
			if (this.cnpjs.length <= 1) {
				this.$store.commit("snackbar/show", {
					content: "O distribuidor deve ter ao menos 1 CNPJ!",
					type: "error",
				});

				return;
			}
			var response = await this.$store.dispatch("dialog/show", {
				title: this.$t("labels.remove"),
				content: this.$t("labels.removeCompanyId"),
			});
			if (
				this.$route.name == "distributor-edit" &&
				typeof this.cnpjs[index].id != "undefined"
			) {
				await this.$store.dispatch(
					"distributor/removeCNPJ",
					this.cnpjs[index].id
				);
				this.$store.commit("snackbar/show", {
					content: this.$t("labels.successCompanyRemove"),
					type: "success",
				});
			}
			if (response) {
				this.cnpjs.splice(index, 1);
			}
		},
	},
};
